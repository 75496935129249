<ng-container *ngIf="(layerStore.activeLayer | async) as activeLayer">
  <div class="popup-container" [ngClass]="data.type === HOVER_POPUP ? 'hover-popup' : ''" *ngIf="(layerStore.activeLevel | async) as activeLevel" [attr.data-cy]="'mapPopup'">
    <button class="close-button" [attr.data-cy]="'close-popup-button'" (click)="handleClose()" *ngIf="data.type !== HOVER_POPUP">
      <mat-icon svgIcon="cancel"></mat-icon>
    </button>

    <ng-container [ngSwitch]="data.type">
      <app-layers-popup-list *ngSwitchCase="DEFAULT_POPUP"
                             [title]="popupService.popupTitle"
                             [properties]="$any(data).properties | sortProperties"
                             [isSeeMoreActive]="popupService.isSeeMoreActive"
                             [activeLayer]="activeLayer"
                             [activeLevel]="activeLevel"
      >
      </app-layers-popup-list>

      <app-layers-popup-list
      *ngSwitchCase="HOVER_POPUP"
      [title]="$any(data).properties.name ?? (layerStore.activeLevel.value === H3_RES5_LEVEL_LAYER ? 'Cell (5 miles)' : 'Cell (1 mile)')"
      [properties]="$any(data).properties | sortProperties"
      [activeLayer]="activeLayer"
      [activeLevel]="activeLevel"
      [isHoverPopup]="true"
      >
      </app-layers-popup-list>

      <app-schools-popup-list *ngSwitchCase="SCHOOLS_POPUP"
                              [properties]="$any(data).properties"
                              [isSeeMoreActive]="popupService.isSeeMoreActive"
      >
      </app-schools-popup-list>

      <app-ori-popup-list *ngSwitchCase="ORI_POPUP"
                          [properties]="$any(data).properties"
                          [isSeeMoreActive]="popupService.isSeeMoreActive"
      ></app-ori-popup-list>
    </ng-container>
    <button class="toggle-data-count" (click)="toggleSeeMore()" *ngIf="data.type !== HOVER_POPUP">
      {{ popupService.isSeeMoreActive ? 'Hide' : 'See more' }}
    </button>

    <div class="popup-tools" *ngIf="data.type === DEFAULT_POPUP">
      <button
        *ngIf="!isUnauthorized"
        (click)="isFeatureFavorite() ? handleRemoveFromFavorites() : handleAddToFavorites()"
        [matTooltip]="'Mark cell as favorite'"
        class="favorites-button"
      >
        {{ isFeatureFavorite() ? 'Saved' : 'Save' }}
        <app-favorite-icon
          strokeColor="#CA0018"
          [fillColor]="isFeatureFavorite() ? '#CA0018' : '#fff'"
          width="14px"
          height="14px"
        >
        </app-favorite-icon>
      </button>

      <button
        *ngIf="accessService.getIsAdmin()"
        (click)="handleDownloadXlsx()"
        [matTooltip]="'Download data (xlsx)'">
        <mat-icon style="margin-bottom: -2px;" svgIcon="download"></mat-icon>
      </button>

      <button
        (click)="handleShare()"
        [matTooltip]="'Copy location to clipboard'">
        <mat-icon style="margin-bottom: -2px;" svgIcon="share"></mat-icon>
      </button>
    </div>
  </div>
</ng-container>
