import { Pipe, PipeTransform } from '@angular/core';
import {
  AIR_POLLUTION,
  allFeatureNames,
  AREA,
  BEAUTY,
  BINGE_DRINKING_RATE,
  BUSINESS_CRIME_RATE,
  CANCER_PREVALENCE,
  COMBINED_HWW_BY_COUNTRY,
  COMBINED_HWW_BY_STATE,
  COMBINED_HWW_CONST,
  COMMUNITY_CRIME_RATE,
  CONVENIENCE_STORES,
  CRIME_RATE,
  CUMULATIVE_TRAFFIC_INTENSITY,
  DIABETES_PREVALENCE,
  DRUGSTORES,
  GENDER_PAY_GAP,
  GINI_INDEX,
  HEALTH_INDEX_BY_COUNTRY,
  HEALTH_INDEX_BY_STATE,
  HEALTH_INDEX_CONST,
  HEART_DISEASES_PREVALENCE,
  HOME_OWNERSHIP_RATIO,
  HOUSEHOLDS,
  LIFE_EXPECTANCY,
  MEDIAN_GROSS_RENT,
  MEDIAN_GROSS_RENT_TREND,
  MEDIAN_HOUSEHOLD_INCOME_TREND,
  MEDIAN_HOUSING_SALE_COST,
  MEDIAN_HOUSING_SALE_COST_TREND,
  MEDIAN_INCOME,
  OBESITY_RATE,
  PATIENT_TO_DENTIST_RATIO,
  PATIENT_TO_PHYSICIAN_RATIO,
  PATIENT_TO_THERAPIST_RATIO,
  PEOPLE_PER_MI2,
  POPULATION,
  POPULATION_GROWTH_TREND,
  RETAIL,
  ROAD_DENSITY,
  SMOKING_RATE,
  SPECIAL_HEALTH_CARE_NEEDS_INDEX,
  UNEMPLOYMENT_RATIO,
  UNHEALTHY_BEHAVIOUR_INDEX,
  UNINSURED_RATIO,
  WEALTH_INDEX_BY_COUNTRY,
  WEALTH_INDEX_BY_STATE,
  WEALTH_INDEX_CONST,
  WISE_INDEX_BY_COUNTRY,
  WISE_INDEX_BY_STATE,
  WISE_INDEX_CONST,
} from '../../shared/types/feature-data-type';

@Pipe({
  name: 'LayerNameFormatPipe',
})
export class LayerNameFormatPipe implements PipeTransform {
  transform(value: string): string {
    return formatLayerName(value);
  }
}

export function formatLayerName(value: string): string {
  switch (value) {
    case AREA: return 'Area, sq mi'
    case POPULATION: return 'Population, ppl';
    case PEOPLE_PER_MI2: return 'Population density, ppl per mi²';
    case MEDIAN_INCOME: return 'Median household income, $';
    case BEAUTY: return 'Beauty, %';
    case CONVENIENCE_STORES: return 'C-stores, %';
    case RETAIL: return 'Supermarkets, %';
    case DRUGSTORES: return 'Pharmacy, %';
    case CRIME_RATE: return 'Total crime rate, offenses per 100K ppl';
    case MEDIAN_GROSS_RENT: return 'Median gross rent, $';
    case MEDIAN_HOUSING_SALE_COST: return 'Median home value, $';
    case LIFE_EXPECTANCY: return 'Life expectancy, years';
    case ROAD_DENSITY: return 'Road density, mi per mi²';
    case CUMULATIVE_TRAFFIC_INTENSITY: return 'Traffic intensity, veh per day';

    case MEDIAN_HOUSING_SALE_COST_TREND: return 'Annual home value growth, %';
    case MEDIAN_GROSS_RENT_TREND: return 'Annual rent growth, %';
    case POPULATION_GROWTH_TREND: return 'Annual population growth, %';
    case MEDIAN_HOUSEHOLD_INCOME_TREND: return 'Annual income growth, %';
    case UNINSURED_RATIO: return 'Uninsured rate, %';
    case UNEMPLOYMENT_RATIO: return 'Unemployment rate, %';

    case SMOKING_RATE: return 'Smoking rate, %';
    case BINGE_DRINKING_RATE: return 'Binge drinking rate, %';
    case OBESITY_RATE: return 'Obesity rate, %';
    case DIABETES_PREVALENCE: return 'Diabetes prevalence, %';
    case CANCER_PREVALENCE: return 'Cancer prevalence, %';
    case HEART_DISEASES_PREVALENCE: return 'Heart disease prevalence, %';
    case AIR_POLLUTION: return 'Air pollution, PM2.5';

    case PATIENT_TO_PHYSICIAN_RATIO: return 'Population-to-physician ratio, ppl';
    case PATIENT_TO_DENTIST_RATIO: return 'Population-to-dentist ratio, ppl';
    case PATIENT_TO_THERAPIST_RATIO: return 'Population-to-therapist ratio, ppl';

    case COMMUNITY_CRIME_RATE: return 'Community crime rate, offenses offenses per 100K ppl';
    case BUSINESS_CRIME_RATE: return 'Business crime rate, offenses per 100K ppl';

    case HOUSEHOLDS: return 'Households';
    case GINI_INDEX: return 'Income Inequality Index (I3)';
    case HOME_OWNERSHIP_RATIO: return 'Homeownership rate, %';
    case GENDER_PAY_GAP: return 'Gender pay gap, %';

    case UNHEALTHY_BEHAVIOUR_INDEX: return 'Unhealthy habits prevalence, %';
    case SPECIAL_HEALTH_CARE_NEEDS_INDEX: return 'Regional health conditions, %';

    case COMBINED_HWW_CONST:
    case COMBINED_HWW_BY_COUNTRY:
    case COMBINED_HWW_BY_STATE:
    case HEALTH_INDEX_CONST:
    case HEALTH_INDEX_BY_COUNTRY:
    case HEALTH_INDEX_BY_STATE:
    case WEALTH_INDEX_CONST:
    case WEALTH_INDEX_BY_COUNTRY:
    case WEALTH_INDEX_BY_STATE:
    case WISE_INDEX_CONST:
    case WISE_INDEX_BY_COUNTRY:
    case WISE_INDEX_BY_STATE:return formatHwwForPopup(value);

    case 'Upcoming: Special healthcare needs': return 'Upcoming: Special healthcare needs'
    default:
      if (allFeatureNames.includes(value)) {
        console.warn('layer-name-format.pipe.ts, cant handle case: ', value)
        return transformString(value)
      }
      return '';
  }
}

export function transformString(string: string): string {
  let transformed = string
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    .replace(/_/g, ' ')
    .toLowerCase()
  // Capitalize the first letter of the first word
  const words = transformed.split(' ');
  if (words.length > 0) {
    words[0] = words[0].charAt(0).toUpperCase() + words[0].slice(1);
  }

  return words.join(' ');
}

export function formatHwwForPopup(layer: string): string {
  return layer
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')
}
