import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { ComparisonService } from '../../../services/comparison.service';
import { feature, Feature } from '@turf/helpers';
import { POPULATION } from '../../../../../shared/types/feature-data-type';
import { WHITE } from '../../colorscale/color-scale.component';
import { ColorscaleService } from '../../colorscale/colorscale.service';

@Component({
  selector: 'app-layers-popup-list',
  templateUrl: './levels-popup-list.component.html',
  styleUrls: [
    './levels-popup-list.component.scss',
    '../map-popup.component.scss',
  ],
})
export class LevelsPopupListComponent implements OnInit, OnChanges {
  @Input() isSeeMoreActive!: boolean;
  @Input() activeLayer!: string;
  @Input() activeLevel!: string;
  @Input() properties!: { [key: string]: any };
  @Input() title!: string;
  @Input() isHoverPopup: boolean = false;

  comparisonResults: Feature['properties'] = {};

  constructor(
    private comparisonService: ComparisonService,
    public colorScaleService: ColorscaleService
  ) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['properties']) {
      this.comparisonService
        .getAndCalculateComparisonData(this.properties)
        .subscribe((results) => {
          for (let result in results) {
            this.comparisonResults![result] = results[result];
          }
        });
    }
  }

  protected readonly feature = feature;
  protected readonly POPULATION = POPULATION;
  protected readonly WHITE = WHITE;
}
