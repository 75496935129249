<div class="colors-container"
     *ngIf="layerStore.activeLayer | async as activeLayer"
     [ngClass]='isCommonColorScale ? "common-colorscale" : ""'>
  <div *ngFor="let color of colorScaleService.colorScaleColors; first as isFirst; last as isLast; let i = index"
       class="color-text-container"
       [ngClass]='isBeingLoaded ? "color-text-container-animated" : ""'
  >
    <div
      class='histogram-color'
      *ngIf="!isCommonColorScale && !isBeingLoaded"
      [ngStyle]="{
      'background-color': color[1],
      'height': (colorScaleService.cellNumHistogram[i]  / Math.max.apply(Math, colorScaleService.cellNumHistogram)) * 35 + 'px',
      'margin-top': -(colorScaleService.cellNumHistogram[i]  / Math.max.apply(Math, colorScaleService.cellNumHistogram)) * 35 + 'px'
       }"
      [matTooltip]=" isBeingLoaded ? 'Loading..'
      : (colorScaleService.cellNumHistogram[i] + ' ' + activeLevel + '\n within '
      + (colorScaleService.colorScaleColors | rangeTextFormatter:i:activeLayer))"
      [matTooltipPosition]="'above'"
    ></div>

    <button
      [ngClass]="[
        isFirst ? 'first-button-as-color' : '',
        isLast ? 'last-button-as-color' : ''
      ]"
      class='button-as-color'
      [ngStyle]="{ 'background-color': color[1],
          'height': isCommonColorScale ? '5px' : ''}"
      [matTooltip]=" isBeingLoaded ? 'Loading..'
      : (colorScaleService.cellNumHistogram[i] + ' ' + activeLevel + '\n within '
      + (colorScaleService.colorScaleColors | rangeTextFormatter:i:activeLayer))"
      [matTooltipPosition]="'above'"
    >
<!--      'border-top': '1rem solid'-->
<!--      [ngStyle]="{ 'background-color': color[1], 'height': isCommonColorScale ? i*10 + 'px' : '' }"-->
      <div class="inner-circle"
           *ngIf="currentFeatureValue && !isBeingLoaded && currentFeatureValueCircleIndex === i"
           [ngStyle]="{'left': currentFeatureValueCirclePosition + '%'}"
           [matTooltip]="'Selected area value : ' + (currentFeatureValue | numberFormatter:2:activeLayer!:true)"
           [matTooltipPosition]="'above'"
      ></div>
    </button>
      <div class='number-value' *ngIf="!isBeingLoaded && !isLast">
        {{ color[0] | numberFormatter: 2:activeLayer}}</div>
  </div>

</div>
